<script>
  export let title;

  import NavBar from "./NavBar.svelte";
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>

<header>
  <NavBar />

  <div class="container">
    {#if title}
      <h1>{title}</h1>
    {/if}

    <slot />
  </div>
</header>

<style>
  @import "../styles/variables.css";

  header {
    padding-bottom: var(--baseline);
  }

  h1 {
    text-align: center;
    font-weight: 500;
    font-size: 25px;
  }
</style>
