<script>
  import NavBarBorder from "../images/nav-bar-border.svg";

  let hidden, scrollY, previousScrollY;
  $: {
    hidden =
      scrollY && previousScrollY && scrollY > 80 && scrollY > previousScrollY;
    previousScrollY = scrollY;
  }
</script>

<svelte:window bind:scrollY />

<nav class="nav-bar" class:hidden>
  <div class="body">
    <a href="https://druidry.org/our-courses/train-in-druidry" class="title">
      <img src="/images/homepage-logo-big.png" alt="Logo" />
      <span class="title-text">Order of Bards, Ovates &amp; Druids</span>
    </a>
  </div>

  <div class="border">
    <NavBarBorder />
  </div>
</nav>

<style>
  @import "../styles/variables.css";

  .nav-bar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--nav-bar-layer);

    display: flex;
    flex-direction: column;

    transition: transform 0.2s ease-in-out;
  }

  .nav-bar.hidden {
    transform: translateY(-4.5rem);
  }

  .body {
    flex-basis: 3.5rem;

    background: var(--primary);
    display: flex;
    justify-content: center;
  }

  .title {
    color: white;

    display: flex;
    align-items: center;

    position: relative;
    top: 0.5rem;
    z-index: 1;
  }

  .title img {
    height: 3rem;
    width: auto;
    margin-right: 1rem;
  }

  .title-text {
    font-weight: 600;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  .border {
    position: relative;
    top: -1px;
    height: 2rem;
  }

  .border :global(svg) {
    color: var(--primary);
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
  }
</style>
